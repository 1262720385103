import React, { useState } from "react"
import { Link } from "gatsby"
import Helmet from "react-helmet"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const Footer = props => {
  const [dark, setDark] = useState(false)
  const toggleDark = () => setDark(!dark)
  return (
    <>
      <Container fluid style={{ maxWidth: "75rem" }}>
        <Row className="d-flex mt-5 mb-4 pt-3 border-top">
          <Col xs="12" md="4" className="text-center text-md-left">
            <span> © {new Date().getFullYear()} Anne Ewers</span>
          </Col>
          <Col xs="12" md="8" className="text-center text-md-right">
            <span className="ml-auto">
              <Link to="/datenschutz/">Datenschutz</Link> |{" "}
              <Link to="/impressum/">Impressum</Link>
            </span>
            <span className="ml-2" onClick={toggleDark}>
              {" "}
              dark
            </span>
          </Col>
        </Row>
      </Container>
      {dark && <Helmet bodyAttributes={{ class: "dark-mode" }}></Helmet>}
    </>
  )
}

export default Footer
