import React, { useState } from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram"

import Navbar from "react-bootstrap/Navbar"
import Container from "react-bootstrap/Container"

const MainNav = props => {
  const [menuOpen, setMenu] = useState(false)
  const toggle = () => setMenu(!menuOpen)
  return (
    <Navbar
      expand="md"
      className={(menuOpen ? "show " : "hidden ") + props.className}
    >
      <Container fluid style={{ maxWidth: "75rem" }}>
        {/* <Navbar.Brand href="/"> */}
        <Link to="/" className="navbar-brand">
          <span className="d-block">kunst-werk.art |</span>
          <span className="d-block" style={{ fontSize: "60%" }}>
            Atelier Anne Ewers
          </span>
        </Link>
        {/* </Navbar.Brand> */}

        <a
          href="https://www.instagram.com/kunst_werk.art"
          target="_blanc"
          className="nav-link d-lg-none p-0 pr-1 mr-1 ml-auto border-right"
        >
          <FontAwesomeIcon icon={faInstagram} className="pr-2" size="2x" />
        </a>

        <a
          className="nav-button d-lg-none pr-0"
          style={{ width: "38px" }}
          onClick={toggle}
        >
          <span id="nav-icon3">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </span>
        </a>
        <div id="basic-navbar-nav" className={menuOpen ? "show" : "hidden"}>
          <div className="main-menu navbar-collapse">
            <div className="navbar-collapse">
              <ul className="nav">
                <NavLink to="/" name="Start" index={1} />
                {/* <NavLink to="/aktuelles/" name="Aktuelles" index={2} /> */}
                <NavLink to="/anne-ewers/" name="Anne Ewers" index={3} />
                <NavLink to="/werke/" name="Werke" index={4} />
                <NavLink to="/kurse/" name="Kunstkurse" index={5} />
                <NavLink to="/kontakt/" name="Kontakt" index={6} />
                <li className="nav-item d-none d-lg-block">
                  <a
                    href="https://www.instagram.com/kunst_werk.art"
                    target="_blanc"
                    className="nav-link py-1 pr-0"
                  >
                    <FontAwesomeIcon
                      icon={faInstagram}
                      className="pr-2"
                      size="2x"
                    />
                  </a>
                </li>
                <li
                  className="my-md-2 ml-2"
                  style={{ borderLeft: "1px solid #6297a7" }}
                ></li>
                <li className="nav-item delay-7">
                  <Link
                    to={"/linn-ewers/"}
                    className="nav-link pl-lg-2 pr-lg-0"
                  >
                    <span className="">Linn Ewers</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </Navbar>
  )
}

const NavLink = ({ name, to, index, className }) => (
  <li className={`nav-item delay-${index} ${className}`}>
    <Link to={to} className="nav-link">
      {name}
    </Link>
  </li>
)

export default MainNav
