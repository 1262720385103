/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

// import Header from "./header"
import MainNav from "./MainNav"
import "../scss/custom.scss"
// import "./layout.css"

import Icon from "../components/icon"

import Container from "react-bootstrap/Container"
import Footer from "./footer"

const Page = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
      <header>
        <MainNav
          siteTitle={data.site.siteMetadata.title}
          className="mb-5 mt-md-2"
        />
      </header>
      <main className="px-md-3">
        <Container fluid style={{ maxWidth: "75rem" }}>
          {children}
        </Container>
        <footer>
          <Footer />
        </footer>
      </main>
    </>
  )
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Page
